<template>
	<div>
        <v-card>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="10"
                    class="elevation-1"
                    :search="search"
                    :custom-filter="filterText"
                    dense
                >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="zoek"
                        class="mx-4"
                    ></v-text-field>
                    </template>
                    <!-- <template v-slot:body.append>
                        <tr>
                            <td></td>
                            <td>
                            <v-text-field
                                v-model="calories"
                                type="number"
                                label="Less than"
                            ></v-text-field>
                            </td>
                            <td colspan="4"></td>
                        </tr>
                    </template> -->
                </v-data-table>
            </v-card-text>
            
        </v-card>
	</div>
</template>

<script>


export default {
	//name: "",
	components: {
		
	},

    props: {
        items: Array,
        headers:Array,
    },

	data: () => ({
        search: '',
	}),

	created() {
       
	},

    methods: {
        filterText (value, search, item) {
            console.log("item = ", item)
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().toUpperCase().indexOf(search.toUpperCase()) !== -1
        },
    },
};
</script>
