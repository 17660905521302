<template>
	<div>
		<v-card class="pa-2 mb-3 " style="opacity: 0.7">
            <h3>Studenten</h3>
		</v-card>

        <v-tabs v-model="tab" color="primary darken-2" background-color="primary lighten-3">
            <v-tab>tabel</v-tab>
            <v-tab>sub-Groepen</v-tab>
            <v-tab>simpel</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" background-color="primary lighten-3">
            <v-tab-item> <!--  v-for="item in items" :key="item" > -->
                <std-table :items='this.items' :headers='this.headers'></std-table>
            </v-tab-item>

            <v-tab-item>
                <groepen :items='this.items' :headers='this.headers'></groepen>
            </v-tab-item>

            <v-tab-item>
                <simpel :items='this.items' :headers='this.headers'></simpel>
            </v-tab-item>
        </v-tabs-items>
	</div>
</template>

<script>

import { getStudents } from "@/api/student.js";
import StdTable from "@/components/students/std_table.vue";
import Groepen from "@/components/students/groepen.vue";
import Simpel from "@/components/students/simpel.vue";

export default {
	//name: "",
	components: {
		StdTable,
        Groepen,
        Simpel
	},

	data: () => ({
		items: [],
        headers: [
            { text: "Groep", value: "groep" },
			{ text: "Naam", value: "naam" },
            { text: "Studentnr.", value: "stdnr"}, /* align: "start", sortable: true  */
            { text: "Woonplaats", value: "plaats" },
            { text: "Telefoon", value: "telefoon" },
            { text: "Mobiel", value: "mobiel" },
		],
        tab:null,
	}),

	created() {
        getStudents().then((apiData) => {
            console.log("getStudents() apiData:", apiData);
			if (apiData.data.bSuccess) {
                    //this.bLoaded = true;
                    this.items = apiData.data.data;

                    this.$root.$emit("showSnackbar", {
                        text: apiData.data.sMessage,
                        type: "success",
                        time: 7000,
                    });
                } else {
                    this.$root.$emit("showSnackbar", {
                        text: "9) "+apiData.data.sMessage,
                        type: "error",
                        time: 7000,
                    });
                }
        });
	},
};
</script>
