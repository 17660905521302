<template>
    <div  class="ma-3" style="height:Calc(100vh - 230px); overflow:auto;">
         <div class="d-flex">
            <v-text-field
                v-model="search_groep"
                label="zoek in groep"
                class="mx-4"
            ></v-text-field>

            <v-text-field
                v-model="search_naam"
                label="zoek in naam"
                class="mx-4"
            ></v-text-field>
        </div>
        
        <!-- mdi-arrow-all mdi-arrow-expand-all mdi-timeline-remove-outline -->
        <div class="d-flex">
            <v-card class="pa-2 ma-2">
                <h3>Studenten</h3>
                <draggable
                    v-model="items"
                    v-bind="dragOptions"
                    group="people"
                >
                    <transition-group type="transition">
                        <div class="sort-item ma-1 px-2 " v-for="std in items" :key="std.id">
                            <v-icon class="pr-2" small>mdi-arrow-expand-all</v-icon> 
                            {{ std.groep }} &nbsp; {{ std.naam }}
                        </div>
                    </transition-group>
                </draggable>
            </v-card>

             <v-card class="pa-2 ma-2">
                <h3>list 1</h3>
                <draggable
                    v-model="list1"
                    v-bind="dragOptions"
                    group="people"
                    :empty-insert-threshold="100"
                >
                    <transition-group type="transition">
                        <div class="sort-item ma-1 px-2 " v-for="std in list1" :key="std.id">
                            <v-icon class="pr-2" small>mdi-arrow-expand-all</v-icon> 
                            {{ std.groep }} &nbsp; {{ std.naam }}
                        </div>
                    </transition-group>
                </draggable>
             </v-card>
             
        </div>

        <!-- <div>
            <div class="ma-2 mt-6">
                <h3>Studenten</h3>
                <div class="" v-for="(field, index) in list3" :key="index">
                    {{index+1}}) {{field.name}}<br>
                </div>
            </div>

            <div class="ma-2 mt-6">
                <h3>list 2</h3>
                <div class="" v-for="(field, index) in list2" :key="index">
                    {{index+1}}) {{field.name}}<br>
                </div>
            </div>
        </div>    -->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    //name: "transition-example-2",

    components: {
        draggable
    },
    props: {
        items: Array,
        headers:Array,
    },
    data() {
        return {
            list1: [],
            list2: [],
            drag: false,

            search_naam: '',
            search_groep: '',
        };
    },
    methods: {
        // sort() {
        //   this.list = this.list.sort((a, b) => a.order - b.order);
        // }
    },

    mounted() {
        this.list1.push(this.items[0]);
    },

    computed: {
        dragOptions() {
            return {
                animation: 500,       
                //group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },

        // filteredList: function() {
        //     //const searchString = this.searchString.toLowerCase();
        //     let filtered = this.items.filter(item => item.naam.toLowerCase().includes(this.search_naam.toLowerCase()));
        //     filtered = filtered.filter(item => item.groep.toLowerCase().includes(this.search_groep.toLowerCase()));
        //     console.log("filtered = ", filtered);
        //     return filtered;
        // }
    },

    watch: {
		//wanneer this.items veranderd van null naar gevuld, runt hij onderstaande
		search_naam: function (newItems) { //, oldItems
			if (newItems) {
				this.items = this.items.filter(item => item.naam.toLowerCase().includes(this.search_naam.toLowerCase()));
                //console.log("filtered = ", filtered);
			}
		},
        search_groep: function (newItems) { //, oldItems
			if (newItems) {
				this.items = this.items.filter(item => item.groep.toLowerCase().includes(this.search_groep.toLowerCase()));
                //console.log("filtered = ", filtered);
			}
		},
	},
};
</script>

<style>
    .sort-item {
        width:400px;
        cursor: move;
        background-color:#EEEEFF;
        border:1px solid gray;
        border-radius:5px;
        margin:0 4px
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .column span {
        display: block; 
        /* height: 100%; */
        height: 300px;
    }


    /* .flip-list-move {
    transition: transform 0.5s;
    }

    .no-move {
    transition: transform 0s;
    } */

</style>