<template>
	<div>
        <div class="d-flex">
            <v-text-field
                v-model="search_groep"
                label="zoek in groep"
                class="mx-4"
            ></v-text-field>

            <v-text-field
                v-model="search_naam"
                label="zoek in achternaam"
                class="mx-4"
            ></v-text-field>
        </div>

        <!-- <div v-for="fld in aSearch" :key="fld.field">
            <input type="text" v-model="fld.field">
            {{fld.title}}
        </div> -->

       <!--  <v-text-field v-for="fld in aSearch" :key="fld.field"
            v-model="search_anaam"
            label= fld.titel
            class="mx-4"
        ></v-text-field> -->

         <!-- <v-text-field 
            v-for="(fld, index) in aSearch" :key="index"
            v-model= "aSearch[n]"
            :label = "aSearch[index].titel"
            class="mx-4"
        ></v-text-field> -->

        <v-card>
            <v-card-text>
                <div v-for="item in filteredList" :key="item.id">
                    {{ item.groep }} &nbsp; {{ item.naam }}
                </div>
            </v-card-text>
        </v-card>
	</div>
</template>

<script>


export default {
	//name: "",

    props: {
        items: Array,
        headers:Array,
    },

	data: () => ({
        search_naam: '',
        search_groep: '',
        // aSearch: [
        //     { field: "anaam", title: "achternaam"},
        //     { field: "vnaam", title: "voornaam"},
        // ],
	}),

    created() {
        console.log("this.items = ", this.items);
       
    },

    methods: {
        filterText (value, search, item) {
            console.log("item = ", item)
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().toUpperCase().indexOf(search.toUpperCase()) !== -1
        },
    },

    computed: {
        filteredList: function() {
            //const searchString = this.searchString.toLowerCase();
            let filtered = this.items.filter(item => item.naam.toLowerCase().includes(this.search_naam.toLowerCase()));
            filtered = filtered.filter(item => item.groep.toLowerCase().includes(this.search_groep.toLowerCase()));
            console.log("filtered = ", filtered);
            return filtered;
        }
    }
};
</script>
